import React from "react";
import { Footer } from "../../Components/Footer/Footer";
// import { AboutUs } from '../../Sections/AboutUs';
import { Header } from "../../Components/Header/Carousals/Header/Header";
import "./AboutUsPage.css";
import { useTranslation } from "react-i18next";
import SectionHeader from "../../Components/SectionHeader";
import AOS from "aos";
// import cover from "../../src/img/glow.gif"
import cover from "../../../src/img/glow.gif";
import aboutBanner from "../../../src/img/banner_about_us1.jpg";

// import img1 from '../img/About_us_2.jpg'
import img1 from "../../../src/img/About_us_2.jpg";

// import img3 from '../img/aboutusNew.gif'
import img3 from "../../../src/img/aboutusNew.gif";
import Qualities from "../../Sections/Qualities";
import professional from "../../img/professional.gif";
import certificate from "../../img/certified.gif";
import company from "../../img/company.gif";
import provider from "../../img/provider.gif";
import whyWe from "../../img/why-choose-us.jpg";
import mission from "../../img/our-mission.jpg";
import vision from "../../img/our-vision.jpg";

const AboutUsPage = () => {
  AOS.init({
    duration: 2000
  });

  const { t } = useTranslation();

  return (
    <>
      {/* <h1 style={{color:"white"}}>Hello</h1> */}
      <Header></Header>
      {/* <AboutUs></AboutUs> */}
      <br />
      <br />

      <div>
        <img src={aboutBanner} style={{ width: "100%" }}></img>
      </div>
      <section id="AboutUs_postPage" className="index-3">
        <div className="container">
          <div
            className="section_headings"
            data-aos="fade-up"

            //  style={{border:"Solid",borderColor:"white",padding:"20px"}}
          >
            <SectionHeader
              title1={t("Heading_data.heading1.title1")}
              title2={t("Heading_data.heading1.title2")}
              headingcontent={t("Heading_data.heading1.headingcontent")}
              data-aos="fade-up"
            />
            {/* <h2>About <span> Us</span></h2> */}
            <div className="separator"></div>

            <div className="col-lg-12 col-md-12 col-xs-12 content_sect">
              <div className="row">
                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"/>
                {/* <img className="imgEffect"
                         src={img3} 
                         alt="AboutCover"/> */}
                <div
                  className="col-lg-5 col-md-5 col-sm-12 col-xs-12 img-area mt-5"
                  // style={{ marginTop: "20px", marginLeft: "15px" }}
                >
                  <img
                    className="img_sect"
                    src={img1}
                    alt="About"
                    data-aos="fade-right"
                  />
                </div>
                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"/>
                {/* <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12"></div> */}
                <div
                  className="col-lg-4 col-md-4 col-sm-12 col-xs-12 content-area-page"
                  style={
                    {
                      // marginLeft:"100px",
                      // marginTop:"20px",
                      //  backgroundImage: `url(${cover})`
                    }
                  }
                  data-aos="fade-left"
                >
                  <h5>{t("Heading_data.heading1.aboutus-content-heading1")}</h5>
                  <p>{t("Heading_data.heading1.aboutus-content1")}</p>
                  {/* <a className="defualt-button view_more" href="about-us.html">View More</a>  */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-12 col-md-12 col-xs-12 content_sec">
      
      </div> */}
          {/* <Qualities /> */}
          <br />
          <br />

          <div class="row" data-aos="fade-up">
            <div class="col-md-3 col-sm-6 container">
              <div class="icon-norm">
                <img src={professional} ></img>
              </div>
              <h3 className="box-heading">
                <strong>{t("About-us-page.D-Box.heading1")}</strong>
              </h3>
              <p className="box-text">{t("About-us-page.D-Box.content1")}</p>
            </div>
            <div class="col-md-3 col-sm-6 highlight container">
              <div class="icon-norm">
                <img src={certificate}></img>
              </div>
              <h3 className="box-heading">
                <strong>{t("About-us-page.D-Box.heading2")}</strong>
              </h3>
              <p className="box-text">{t("About-us-page.D-Box.content2")}</p>
            </div>
            <div class="col-md-3 col-sm-6 container">
              <div class="icon-norm">
                <img src={company}></img>
              </div>
              <h3 className="box-heading">
                <strong>{t("About-us-page.D-Box.heading3")}</strong>
              </h3>
              <p className="box-text">{t("About-us-page.D-Box.content3")}</p>
            </div>
            <div class="col-md-3 col-sm-6 container">
              <div class="icon-norm">
                <img src={provider}></img>
              </div>
              <h3 className="box-heading">
                <strong>{t("About-us-page.D-Box.heading4")}</strong>
              </h3>
              <p className="box-text">{t("About-us-page.D-Box.content4")}</p>
            </div>
          </div>

          <br />
          <br />
          <br />
          <section id="about-detail">
            <div className="container">
              <div
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mid-content1"
                data-aos="fade-up"
              >
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 img-area-abt">
                    {" "}
                    <img
                      className="aboutBottom"
                      src={whyWe}
                      alt="Why Choose Us"
                    />{" "}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-area-abt">
                    <div className="section_headings">
                      <h4>
                        {t("About-us-page.side-section-heading1.title1")}{" "}
                        <span>
                          {t("About-us-page.side-section-heading1.title2")}
                        </span>
                      </h4>
                      <div className="separator left-align "></div>
                    </div>
                    <div className="descriptions">
                      <p>{t("About-us-page.side-section-1")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mid-content1"
              data-aos="fade-up"
            >
              <div className="row row-reverse">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-area-abt">
                  <div className="section_headings">
                    <h4>
                      {t("About-us-page.side-section-heading2.title1")}{" "}
                      <span>
                        {t("About-us-page.side-section-heading2.title2")}
                      </span>
                    </h4>
                    <div className="separator left-align "></div>
                  </div>
                  <div className="descriptions">
                    <p>{t("About-us-page.side-section-2")}</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 img-area-abt">
                  {" "}
                  <img
                    className="aboutBottom"
                    src={mission}
                    alt="Our Mission"
                  />{" "}
                </div>
              </div>
            </div>
            <div
              className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mid-content1"
              data-aos="fade-up"
            >
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 img-area-abt">
                  {" "}
                  <img
                    className="aboutBottom"
                    src={vision}
                    alt="Our Mission"
                  />{" "}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 content-area-abt">
                  <div className="section_headings">
                    <h4>
                      {t("About-us-page.side-section-heading3.title1")}{" "}
                      <span>
                        {t("About-us-page.side-section-heading3.title2")}
                      </span>
                    </h4>
                    <div className="separator left-align "></div>
                  </div>
                  <div className="descriptions">
                    <p>{t("About-us-page.side-section-3")}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="clearfix"></div>
      </section>

      <Footer></Footer>
    </>
  );
};

export default AboutUsPage;

import React from "react";
import { Header } from "../../Components/Header/Carousals/Header/Header";
import { Footer } from "../../Components/Footer/Footer";
import { Services } from "../../Sections/Services";
import globe from "../../../src/img/globe.gif";
import image1 from "../../img/GpsTracking.jpg";
import image3 from "../../img/fuelManagemnt.jpg";
import image2 from "../../img/loadMonitoring.jpg";
import image4 from "../../img/mobileAppDev.jpg";
import image5 from "../../img/Web-Design-Development.jpg";
import image6 from "../../img/backendDev.jpg";
import image7 from "../../img/digital-marketing.jpg";
// import image7 from "../../img/digital-marketing.jpg";
import { useTranslation } from "react-i18next";
import "./ServicesPage.css";

import SectionHeader from "../../Components/SectionHeader";
import { Heading_data } from "../../Data/Home_Data";
import AOS from "aos";
import ServiceBanner from "../../img/service-banner-hd.png";
import { t } from "i18next";

const ServicesPage = () => {
  AOS.init({
    duration: 2000
  });

  const { t } = useTranslation();

  return (
    <>
      <Header></Header>
      <br />
      <br />
      <div>
        <img
          src={ServiceBanner}
          className="servicebannerImg"
          // style={{ height: "700px", width: "100%" }}
        ></img>
      </div>
      <br />
      {/* <Services></Services> */}
      <>
        <section
          id="portfolio"
          data-aos="fade-up"
          // style={{ backgroundImage: `url(${globe})` }}
        >
          <div className="container">
            <SectionHeader
              title1={t("Heading_data.heading2.title1")}
              title2={t("Heading_data.heading2.title2")}
              //  headingcontent={Heading_data[0].headingcontent}
              headingcontent={t("Heading_data.heading2.headingcontent")}
              data-aos="fade-up"
            />

            <section class="subpageblock">
              <div class="container visionblockService">
                {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Gps Tracking Service>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                <div class="row aos-init aos-animate" data-aos="fade-up">
                  <div class="col-md-6 order-2 order-md-1">
                    <img
                      src={image1}
                      alt=""
                      class="serviceImg"
                    />
                  </div>
                  <div class="col-md-6  order-1 order-md-2 ">
                    <h4
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("ServicesTitles.names.service_name1")}
                    </h4>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("ServicesTitles.s-content.service-content1")}
                    </p>
                  </div>
                </div>

                {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Load Monitoring >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                <div class="row aos-init aos-animate" data-aos="fade-up">
                  <div class="col-md-6">
                    <h4
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("ServicesTitles.names.service_name2")}
                    </h4>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("ServicesTitles.s-content.service-content2")}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src={image2}
                      alt=""
                      class="serviceImg"
                    />
                  </div>
                </div>

                {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Fuel Management Service >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                <div class="row aos-init aos-animate" data-aos="fade-up">
                  <div class="col-lg-5 col-sm-12 order-2 order-md-1">
                    <img
                      src={image3}
                      alt=""
                      class="img-responsive serviceImg"
                    />
                  </div>
                  <div class="col-lg-7 col-sm-12 order-1 order-md-2">
                    <h4
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("ServicesTitles.names.service_name3")}
                    </h4>
                    <p>{t("ServicesTitles.s-content.service-content3")}</p>
                    {/* <ul>
    <li>Ethical Values</li>
    <li>Commitment</li>
    <li>Dedication</li>
    <li>Reliability</li>
    <li>Transparency</li>
  </ul> */}
                  </div>
                </div>

                {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Mobile App Development>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                <div class="row aos-init aos-animate" data-aos="fade-up">
                  <div class="col-md-6">
                    <h4
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("ServicesTitles.names.service_name4")}
                    </h4>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("ServicesTitles.s-content.service-content4")}
                    </p>
                  </div>
                  <div class="col-md-6">
                    <img
                      src={image4}
                      alt=""
                      class="img-responsive serviceImg"
                    />
                  </div>
                </div>

                {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Web Design & Development>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                <div class="row aos-init aos-animate" data-aos="fade-up">
                  <div class="col-md-5 col-sm-6 order-2 order-md-1">
                    <img
                      src={image5}
                      alt=""
                      class="img-responsive serviceImg"
                    />
                  </div>
                  <div class="col-md-7 col-sm-6 order-1 order-md-2">
                    <h4
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("ServicesTitles.names.service_name5")}
                    </h4>
                    <p>{t("ServicesTitles.s-content.service-content5")}</p>

                    {/* <ul>
    <li>Ethical Values</li>
    <li>Commitment</li>
    <li>Dedication</li>
    <li>Reliability</li>
    <li>Transparency</li>
  </ul> */}
                  </div>
                </div>

                {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Backend Development>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                <div class="row aos-init aos-animate" data-aos="fade-up">
                  <div class="col-md-6 col-sm-6 col-sm-pull-6 col-md-pull-6">
                    <h4
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("ServicesTitles.names.service_name6")}
                    </h4>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("ServicesTitles.s-content.service-content6")}
                    </p>
                  </div>
                  <div class="col-md-6 col-sm-6 col-sm-push-6 col-md-push-6">
                    <img
                      src={image6}
                      alt=""
                      class="img-responsive serviceImg"
                    />
                  </div>
                </div>

                {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Digital Marketing>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                <div class="row aos-init aos-animate" data-aos="fade-up">
                  <div class="col-md-5 col-sm-6 order-2 order-md-1">
                    <img
                      src={image7}
                      alt=""
                      class="img-responsive serviceImg"
                    />
                  </div>
                  <div class="col-md-7 col-sm-6 order-1 order-md-2">
                    <h4
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("ServicesTitles.names.service_name7")}
                    </h4>
                    <p>{t("ServicesTitles.s-content.service-content7")}</p>

                    {/* <ul>
    <li>Ethical Values</li>
    <li>Commitment</li>
    <li>Dedication</li>
    <li>Reliability</li>
    <li>Transparency</li>
  </ul> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </>

      <br />
      <Footer></Footer>
    </>
  );
};

export default ServicesPage;
import React from "react";
// import Post from "../components/Post"
// import ServicesPostData from '../Data/ServicesData';
// import Services1 from "../img/Services-post-1.jpg";
// import Services2 from "../img/Services-post-2.jpg";
// import Services3 from "../img/Services-post-3.jpg";
import { ServiceSection } from "../Data/Home_Data";
// import { Aos } from 'aos'
import AOS from "aos";
import globe from "../../src/img/globe.gif";
import "./Services.css";
import SectionHeader from "../Components/SectionHeader";
import { Heading_data } from "../Data/Home_Data";
import { useTranslation } from "react-i18next";

export const Services = ({ image, service_name, content }) => {
  const { t } = useTranslation();

  AOS.init({
    duration: 2000
  });

  return (
    <section
      id="Services_post"
      className="index-3"
      style={{ backgroundImage: `url(${globe})` }}
      data-aos="fade-left"
    >
      <div className="container">
        <div className="section_heading" data-aos="fade-up">
          <SectionHeader
            title1={t("ServiceSection.serviceTitle1")}
            title2={t("ServiceSection.serviceTitle2")}
            headingcontent={t("ServiceSection.serviceMainContenet")}
          />
          {/* <div className="heading_content"> Lorem Ipsum has been the {`industry's`} standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </div> */}
          <div className="separator">
            {/* <h2>Fleet Management</h2>
            <ul>Vehicle GPS Tracking</ul>
            <ul>Fuel Management</ul>
            <ul>Vehicle Load Monitoring Solutions</ul>
            <h2>IT Services</h2>
            <ul>Mobile Application development</ul>
            <ul>Website development</ul>
            <ul>Backend development</ul>
             <ul>Digital Marketing</ul> */}
            <div className="col-lg-12 col-md-12 col-xs-12 content_sec">
              <div className="row">
                {ServiceSection.map((data) => {
                  const { image, service_name, content } = data;
                  return (
                    // <TeamMemberCard
                    //     // key={pic}
                    //     // pic={pic}
                    //     name={name}
                    //     position={position}
                    //     content={content}
                    //     mail={mail}
                    // />
                    <div className="item col-lg-4 col-md-4 col-sm-12 col-xs-12">
                      <div className="client_dp">
                        {/* <img src={pic} alt="Team" /> */}
                      </div>
                      <div className="team-card">
                        <div className="name_designation" data-aos="flip-left">
                          <img
                            className="imgEffect"
                            src={image}
                            height={"300px"}
                            width={"400px"}
                          ></img>
                          {/* <Link to="/teampage">{name}</Link> */}

                          <h5>{service_name}</h5>
                        </div>
                        <div className="description">
                          <p>{content}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                style={{ backgroundImage: `url(${globe})` }}
                data-aos="fade-left"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-md-12 col-xs-12 content_sec"></div>
      </div>
      <div className="clearfix"></div>
    </section>
  );
};

// Fleet management includes vehicle financing, vehicle
// maintenance, vehicle telematics (tracking and diagnostics), driver
// management, fuel management and health and safety management.
// Advanced fleet management systems can easily connect to the
// vehicle’s on-board computer, and gather data for the user. Data
// collected includes details such as mileage, fuel
// consumption, and much more, which are then gathered into a global
// statistics scheme.

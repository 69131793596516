// const carouselConstant = {
//   COURSES: "COURSES",
//   TEST: "TEST",
//   COLLEGES: "COLLEGES",
//   SCHOOLS: "SCHOOLS",
//   GOVERNMENT_SCHEMES: "GOVERNMENT_SCHEMES",
//   COURSE_DETAIL: "courseDetail",
//   HOMEPAGE: "homePage",
// };

const routingConstants = {
  ABOUT: "/about/",
  BLOGS: "/blogs",
  INDUSTRIES: "/industries",
  CONTACT: "/contact-us",
  SERVICES: "/services",
  HOME_PAGE: "/"
 };


export { routingConstants };

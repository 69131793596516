import logo from './logo.svg';
// import React from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import React from "react";
import './App.css';
import { Header } from './Components/Header/Carousals/Header/Header';
import { routingConstants } from "./utils/constants";
const HomePage = React.lazy(() => import("./Pages/Home/HomePage"));
const BlogsPage = React.lazy(()=>import ("./Pages/Blogs/BlogsPage"));
const AboutUsPage = React.lazy(()=>import ("./Pages/AboutUs/AboutUsPage"));
const ContactPage = React.lazy(()=>import ("./Pages/ContactUs/ContactPage"));
const ServicesPage = React.lazy(()=>import ("./Pages/OurServices/ServicesPage"));
const IndustriesPage  = React.lazy(()=>import("./Pages/Industries/IndustriesPage"));

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <div className="App">
      {/* <header className="App-header"> */}
      <ThemeProvider theme={darkTheme}>
<CssBaseline />

       <BrowserRouter>
      <Switch>
      <Route exact path={routingConstants.HOME_PAGE} component={HomePage} />
      <Route exact path={routingConstants.SERVICES} component={ServicesPage} />
      <Route exact path={routingConstants.INDUSTRIES} component={IndustriesPage} />
      <Route exact path={routingConstants.BLOGS} component={BlogsPage} />
      <Route exact path={routingConstants.ABOUT} component={AboutUsPage} />
      <Route exact path={routingConstants.CONTACT} component={ContactPage} />
      
            </Switch>
</BrowserRouter>
</ThemeProvider>
    </div>
  );
}

export default App;

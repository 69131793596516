import React, { Suspense } from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter} from 'react-router-dom';
import "./assets/i18n/i18n";
import {Provider} from "react-redux"
import store from "./store"
// import { Loader } from "./components";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
       {/* <Suspense fallback={<Loader />}> */}
       <Suspense>
     <Provider store={store}>
    <BrowserRouter>
  <App />
 </BrowserRouter>
 </Provider>
 </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


import { getImageListItemBarUtilityClass } from "@mui/material";
import React from "react";

import professional from "../img/professional.gif";
import certificate from "../img/certified.gif";
import company from "../img/company.gif";
import provider from "../img/provider.gif";

import portfolio_a from "../img/refrigerated-cargo-industry.jpg";
import portfolio_b from "../img/ready-mix-industry.jpg";
import portfolio_c from "../img/Waste-management-industry .jpg";
import portfolio_d from "../img/schoolbus-transport-solutions-industry.jpg";
import portfolio_e from "../img/ai-driver-evaluation-simulator-industry.jpg";
import portfolio_f from "../img/fuel-monitoring-industry.jpg";

import ourclient_d from "../img/al _ghazal.jpg";
import ourclient_e from "../img/injaz.jpg";
import ourclient_f from "../img/logic-cars.jpg";

// >>>>>>>>>>>  Service Section >>>>>>>>>>>>>>>>>>>

// import image1 from "../img/vehicleTracking.png";
import image1 from "../img/GpsTracking.jpg";
import image3 from "../img/fuelManagemnt.jpg";
import image2 from "../img/loadmonit.jpg";
import image4 from "../img/mobileAppDev.jpg";
import image5 from "../img/Web-Design-Development.jpg";
import image6 from "../img/backendDev.jpg";
import image7 from "../img/digital-marketing.jpg";

const ServiceSection = [
  {
    image: image1,
    service_name: "Vehicle GPS Tracking",
    content:
      "provide information on the current location, direction, time, and speed of the vehicle being tracked.This network consists of satellites that communicate with GPS devices"
  },
  {
    image: image2,
    service_name: "Vehicle Load Monitoring Solutions",
    content:
      "Provide an accurate and reliable representation of the applied load. Our load control system is more convenient, accurate and quick way of truck and trailer weighing"
  },
  {
    image: image3,
    service_name: "Fuel Management",
    content:
      "Provide the accurate mileage and fuel consumption of vehicle. It also detects the fuel wastage based on parameter such as idling, hard acceleration, deceleration and helps in saving huge on fuel costs"
  },
  {
    image: image4,
    service_name: "Mobile Application development",
    content:
      "Use our best in class mobile app development project plan in order to build your own path now. Focus on drafting your concept and developing truly successful apps on a reasonable pricing"
  },
  {
    image: image5,
    service_name: "Website development",
    content:
      "We also deal in the field of Web design and development. We develop websites and applications that are easy to use, visually appealing, and accessible to a wide range of devices"
  },
  {
    image: image6,
    service_name: "Backend development",
    content:
      "Backend development is crucial for the success of a web application and App. It handles the business logic while simultaneously ensuring the front-end development work is well integrated and delivered"
  },
  {
    image: image7,
    service_name: "Digital Marketing",
    content:
      "Our digital marking services connects a business with its customers when they are online. It connects businesses with ideal customers when they are on Google through SEO & PPC, on social media with social media marketing"
  }
  // {
  //   image : image8 ,
  //   service_name:"",
  //   content:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  // }
];

const Sdata = [
  {
    // pic: img1,
    name: "Mohammed Salem",
    position: "CEO",
    content:
      "Extremely capable, organized, determined man who has a great personality and an even temperament. developing high-quality business plans and strategies ensuring their alignment with long term and short term objectives to increase profits and advance business"
  },
  {
    // pic: img4,
    name: "Tiwari Srikanth",
    position: "Flutter Developer",
    content:
      "Dynamic and professional person with best in class knowledge of app development. Works on cross platform mobile app development, and can create an amazing UI and can build a robust and high quality app for iOS and Android with ease for you mobile app"
  },
  {
    // pic: img3,
    name: "Vivek",
    position: "UI Designer",
    content:
      "A skillful Interface (UI) Designer and a professional who works closely with a User Experience (UX) to create and implement a user interface that is just not intuitive but also has the right look and feel required in the product design"
  },
  {
    // pic: img2,
    name: "Nishat Fatema",
    position: "Web Developer",
    content:
      "A good web developer with combination of technical skills, problem-solving abilities, and a strong work ethic, responsible for the design and construction of websites. Ensure that sites meet user expectations by formulating a website that look good and run smoothly"
  }
];

const Portfolio_data = [
  {
    content: "Refrigerated Cargo Industries",
    img_thumbnail: portfolio_a,
    // img_large: portfolio_A,
    cls: "design development"
  },
  {
    content: "Ready Mix Industry",
    img_thumbnail: portfolio_b,
    // img_large: portfolio_B,
    cls: "app logo"
  },
  {
    content: "Waste Management",
    img_thumbnail: portfolio_c,
    // img_large: portfolio_C,
    cls: "development logo"
  },
  {
    content: "School Transport Solutions",
    img_thumbnail: portfolio_d,
    // img_large: portfolio_D,
    cls: "design development logo"
  },
  {
    content: "AI Driver Evaluation Simulator",
    img_thumbnail: portfolio_e,
    // img_large: portfolio_E,
    cls: "app web development"
  },
  {
    content: "Fuel Monitoring",
    img_thumbnail: portfolio_f,
    // img_large: portfolio_F,
    cls: "design development"
  }
];

const Heading_data = [
  {
    title1: "Our ",
    title2: "Team",
    headingcontent: `Great things in business are never done by one person. They’re done by a team of people. We work together to cherish the human spirit, understanding that the people are the heart of successful companies. We create and implement social programs within the company designed to give our employees a high quality of life both at work and at home. We understand the power of volunteers in our communities and foster employee community involvement through encouraging creative projects that benefit community wellbeing and the environment. Following are some of our team members`
  }
];
const Heading_data2 = [
  {
    title3: "Industries ",
    title4: "We Serve ",
    headingcontent3: `Think about the brands you purchase from over and over. Why do you choose to buy products and or services from them even when other options exist? Well, there's a good reason for it — because of their values which are expressed in their mission statement. As consumers, we like to patronize businesses that have values we believe in.`
  }
];

const AboutQualitiesData = [
  {
    icon: professional,
    heading: "We are Professional"
  },
  {
    icon: certificate,
    heading: "Licensed and Certified"
  },
  {
    icon: company,
    heading: "Number 1 Company"
  },
  {
    icon: provider,
    heading: "Leading Provider"
  }
];

// ABout Deatil Data
// const Aboutdetail_data = [
//   {
//      src: qimg1,
//      alt: "Why Choose Us",
//      title1: "Why ",
//      title2: "Choose Us?"
//   },
//   {
//      src: qimg2,
//      alt: "Our Mission",
//      title1: "Our ",
//      title2: "Mission"
//   },

//   {
//       src: qimg3,
//       alt: "Our Vision",
//       title1: "Our ",
//       title2: "Vision"
//    },
// ]
// const AboutQualitiesData = [
//   {
//       icon: "flat flaticon-reward",
//       heading: "We are Professional",
//   },
//   {
//       icon: "flat flaticon-certificate",
//       heading: "Licensed and Certified",
//   },
//   {
//       icon: "flat flaticon-enterprise",
//       heading: "Number 1 Company",
//   },
//   {
//       icon: "flat flaticon-working-team",
//       heading: "Leading Provider",
//   }
// ]
/////// PORTFOLIO SECTION DETAILS///////
// const Portfolio_data = [
//   {
//     content: "Photography",
//     img_thumbnail: portfolio_a,
//     img_large: portfolio_A,
//     cls:"design development"
//   },
//   {
//     content: "Ecommerce",
//     img_thumbnail: portfolio_b,
//     img_large: portfolio_B,
//     cls:"app logo"
//   },
//   {
//     content: "Education",
//     img_thumbnail: portfolio_c,
//     img_large: portfolio_C,
//     cls:"development logo"
//   },
//   {
//     content: "Real Estate",
//     img_thumbnail: portfolio_d,
//     img_large: portfolio_D,
//     cls:"design development logo"
//   },
//   {
//     content: "Bar & Restaurant",
//     img_thumbnail: portfolio_e,
//     img_large: portfolio_E,
//     cls:"app web development"
//   },
//   {
//     content: "Saloon & Spa",
//     img_thumbnail: portfolio_f,
//     img_large: portfolio_F,
//     cls:"design development"
//   }
// ];
//pricingdata
// const Pdata = [
//   {
//       heading : "Personal",
//       price : "9$"
//   },
//   {
//       heading : "Ultimate",
//       price : "199$"
//   },
//   {
//       heading : "Business",
//       price : "99$"
//   }

// ]
//Services Data
// const Services_Data = [
//   {
//     title: "Web Development",
//     icon: "web-design",
//     content:
//       `All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.`
//   },
//   {
//     title: "Web Designing",
//     icon: "computer-graphic",
//     content:
//       `All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.`
//   },
//   {
//     title: "Web Hosting",
//     icon: "servers",
//     content:
//       `All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.`
//   },
//   {
//     title: "Digital Marketing",
//     icon: "digital-marketing",
//     content:
//       `All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.`
//   },
//   {
//     title: "SEO / SMO",
//     icon: "computer-graphic",
//     content:
//       `All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.`
//   }
// ];
////// TESTIMONIAL SECTION DETAILS///////
// const Testimonail_data = [
//   {
//       pic:about_img1,
//       name: "Rebecca Stormville" ,
//       position:"Designer" ,
//       content:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
//   },
//   {
//       pic:about_img1,
//       name: "Rebecca Stormville" ,
//       position:"Designer" ,
//       content:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
//   },
//   {
//       pic:about_img1,
//       name: "Rebecca Stormville" ,
//       position:"Designer" ,
//       content:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
//   },
//   {
//       pic:about_img1,
//       name: "Rebecca Stormville" ,
//       position:"Designer" ,
//       content:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
//   },
//   {
//       pic:about_img1,
//       name: "Rebecca Stormville" ,
//       position:"Designer" ,
//       content:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
//   },

//   {
//       pic:about_img1,
//       name: "Rebecca Stormville" ,
//       position:"Designer" ,
//       content:"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. "
//   },
// ]
///// OurClient Section Data ///////
const ClientData = [
  // {
  //     pic:ourclient_a
  // },
  // {
  //     pic:ourclient_b
  // },
  // {
  //     pic:ourclient_c
  // },
  {
    pic: ourclient_d
  },
  {
    pic: ourclient_e
  },
  {
    pic: ourclient_f
  }
];
//Home Counter
// const HCDAta = [
//   {
//     icon:"happiness",
//     counter:200,
//     title:"Happy Client"
//   },
//   {
//     icon:"coffee-cup",
//     counter:750,
//     title:"Cups Of Coffee"
//   },
//   {
//     icon:"like",
//     counter:1800,
//     title:"Projects Done"
//   },
//   {
//     icon:"building",
//     counter:15,
//     title:"Office Location"
//   },

// ]
// const SDDATA= [
//   {
//     counter:"87K",
//     title:"Projects Done"
//   },
//   {
//     counter:8782,
//     title:"Hours Work"
//   },
//   {
//     counter:682,
//     title:"Clients"
//   },

// ]
// export { Sdata, Heading_data,Services_Data,HCDAta,SDDATA, Testimonail_data,Portfolio_data ,ClientData, Pdata,Aboutdetail_data,AboutQualitiesData};

export {
  Heading_data,
  ClientData,
  Sdata,
  ServiceSection,
  Portfolio_data,
  Heading_data2,
  AboutQualitiesData
};

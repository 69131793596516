import React, { useState } from "react";
import { translation } from "../../../../../src/store/language/";
import { useTranslation } from "react-i18next";
import {  useHistory } from "react-router-dom";
import { routingConstants } from '../../../../utils/constants';
// import MenuIcon from "@material-ui/icons/Menu";
import trackersLogo from "../../../../img/trackerslogo.png";


// import {
//   Drawer,
//   IconButton,
//   List,
//   ListItem,
//   ListItemText,
//  makeStyles
// } from "@material-ui/core";
// import makeStyles from '@mui/material/makeStyles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import { Link } from "react-router-dom";
import "./Drawer.css"

// const useStyles = makeStyles(()=>({
//     link:{
//         textDecoration:"none",
//         color: "blue",
//         fontSize: "20px",
//     },
//     icon:{
//         color: "white"
//     }
// }));

function DrawerComponent() {

    const { t } = useTranslation();
    const history = useHistory();

// const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
       
        <List>
         <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
            <img src={trackersLogo} href="/" className='Headerlogo'></img>
            </ListItemText>
            </ListItem>
         <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/" className="side-links"> {t("header.headings.1")}</Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to= {routingConstants.SERVICES} className="side-links"> {t("header.headings.2")}</Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to= {routingConstants.INDUSTRIES} className="side-links"> {t("header.headings.3")}</Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link  to= {routingConstants.BLOGS} className="side-links"> {t("header.headings.4")}</Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to= {routingConstants.ABOUT} className="side-links"> {t("header.headings.5")}</Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to= {routingConstants.CONTACT} className="side-links"> {t("header.headings.6")}</Link>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
      {/* <img src={trackersLogo} href="/" className='Headerlogo'></img> */}
      <MenuIcon />
      </IconButton>
    </>
  );
}
export default DrawerComponent;



import React from "react";
import { Header } from "../../Components/Header/Carousals/Header/Header";
import { Footer } from "../../Components/Footer/Footer";
import stars from "../../img/industrywall.jpg";

import industriesBanner from "../../img/fleet_baner1.jpg";
// import industriesBanner from "../../img/industries.jpg"
// import industriesBanner from "../../img/Fleet-Hero-Image.png"
// import industriesBanner from "../../img/fleet-page.jpg"

import MainIndustries from "../../Sections/MainIndustries";
import portfolio_a from "../../img/refrigerated-cargo-industry.jpg";
import portfolio_b from "../../img/ready-mix-industry.jpg";
import portfolio_c from "../../img/Waste-management-industry .jpg";
import portfolio_d from "../../img/schoolbus-transport-solutions-industry.jpg";
import portfolio_e from "../../img/ai-driver-evaluation-simulator-industry.jpg";
import portfolio_f from "../../img/fuel-monitoring-industry.jpg";

import { Heading_data2 } from "../../Data/Home_Data";
import SectionHeader from "../../Components/SectionHeader";
import "./IndustriesPage.css";
import AOS from "aos";
import { useTranslation } from "react-i18next";

const IndustriesPage = () => {
  AOS.init({
    duration: 2000
  });

  const { t } = useTranslation();

  return (
    <>
      <Header></Header>
      <br />
      <br />
      <div>
        <img
          src={industriesBanner}
          // style={{height:"700px"}}
          // style={{width:"1900px"
          // height:"600px"
          // }}
        ></img>
      </div>
      {/* <br />
  <br /> */}

      <>
        {/* <Banner heading="Portfolio"/> */}
        <section
          id="portfolio"
          data-aos="fade-up"
          style={{
            backgroundImage: `url(${stars})`,
            backgroundRepeat: "repeat-y"
          }}
        >
          <div className="container">
            <SectionHeader
              title1={t("Heading_data.heading6.title1")}
              title2={t("Heading_data.heading6.title2")}
              headingcontent={t("Heading_data.heading6.headingcontent")}
              data-aos="fade-up"
            />
            {/* <MainIndustries /> */}

            <section class="subpageblock">
              <div class="container visionblock">
                {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>refrigerated-cargo-industry>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                <div class="row aos-init aos-animate" data-aos="fade-up">
                  <div class="col-md-6 col-sm-6 order-2 order-md-1">
                    <img src={portfolio_a} alt="" class="img-responsive" />
                  </div>
                  <div class="col-md-6 col-sm-6 order-1 order-md-2">
                    <h4
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("Industry.titles.industry_name1")}
                    </h4>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("Industry.details.industry_detail1")}
                    </p>
                  </div>
                </div>

                {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>ready-mix-industry>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                <div class="row aos-init aos-animate" data-aos="fade-up">
                  <div class="col-md-6 col-sm-6 col-sm-pull-6 col-md-pull-6">
                    <h4
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("Industry.titles.industry_name2")}
                    </h4>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("Industry.details.industry_detail2")}
                    </p>
                  </div>
                  <div class="col-md-6 col-sm-6 col-sm-push-6 col-md-push-6">
                    <img src={portfolio_b} alt="" class="img-responsive" />
                  </div>
                </div>

                {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Waste-management-industry>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                <div class="row aos-init aos-animate" data-aos="fade-up">
                  <div class="col-md-6 col-sm-6 order-2 order-md-1">
                    <img src={portfolio_c} alt="" class="img-responsive" />
                  </div>
                  <div class="col-md-6 col-sm-6 order-1 order-md-2">
                    <h4
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("Industry.titles.industry_name3")}
                    </h4>
                    {/* <ul>
    <li>Ethical Values</li>
    <li>Commitment</li>
    <li>Dedication</li>
    <li>Reliability</li>
    <li>Transparency</li>
  </ul> */}
                    <p
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("Industry.details.industry_detail3")}
                    </p>
                  </div>
                </div>

                {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>schoolbus-transport-solutions-industry>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                <div class="row aos-init aos-animate" data-aos="fade-up">
                  <div class="col-md-6 col-sm-6 col-sm-pull-6 col-md-pull-6">
                    <h4
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("Industry.titles.industry_name4")}
                    </h4>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("Industry.details.industry_detail4")}
                    </p>
                  </div>
                  <div class="col-md-6 col-sm-6 col-sm-push-6 col-md-push-6">
                    <img src={portfolio_d} alt="" class="img-responsive" />
                  </div>
                </div>

                {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>ai-driver-evaluation-simulator-industry>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                <div class="row aos-init aos-animate" data-aos="fade-up">
                  <div class="col-md-6 col-sm-6 order-2 order-md-1">
                    <img src={portfolio_e} alt="" class="img-responsive" />
                  </div>
                  <div class="col-md-6 col-sm-6 order-1 order-md-">
                    <h4
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("Industry.titles.industry_name5")}
                    </h4>
                    {/* <ul>
    <li>Ethical Values</li>
    <li>Commitment</li>
    <li>Dedication</li>
    <li>Reliability</li>
    <li>Transparency</li>
  </ul> */}
                    <p
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("Industry.details.industry_detail5")}
                    </p>
                  </div>
                </div>

                {/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>fuel-monitoring-industry>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                <div class="row aos-init aos-animate" data-aos="fade-up">
                  <div class="col-md-6 col-sm-6 col-sm-pull-6 col-md-pull-6">
                    <h4
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("Industry.titles.industry_name6")}
                    </h4>
                    <p
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      class="aos-init aos-animate"
                    >
                      {t("Industry.details.industry_detail6")}
                    </p>
                  </div>
                  <div class="col-md-6 col-sm-6 col-sm-push-6 col-md-push-6">
                    <img src={portfolio_f} alt="" class="img-responsive" />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        {/* <Counter data={HCDAta} cls overlay/> */}
      </>

      <Footer></Footer>
    </>
  );
};

export default IndustriesPage;
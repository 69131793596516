import React from "react";
// import {  Heading_data } from "../Data/Home_Data";

const SectionHeader = ({ title1, title2, headingcontent }) => {
  return (
    <div className="section_heading">
      <h2>
        {title1}
        <span style={{ color: "blueviolet" }}>{title2}</span>
      </h2>
      <div className="separator">
        {/* <ul>
              <li></li>
              <li></li>
              <li></li>
            </ul> */}
      </div>
      <div
        className="heading_content"
        // style={{ paddingTop: "20px", textAlign: "justify" }}
      >
        {" "}
        {headingcontent}
      </div>
    </div>
  );
};

export default SectionHeader;

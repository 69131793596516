// import React from "react";
import { Header } from "../../Components/Header/Carousals/Header/Header";
import { Footer } from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import ContactUs from "./ContactUs";
import ContactInfo from "./ContactInfo";
import contactBanner from "../../img/contact-us-banner1.jpg";
// import contactBanner from "../../img/contact-us-banner.jpg"

const ContactPage = () => {
    return (
      <>
        <Header></Header>
        <br />
        <br />
        <div>
          <img
            src={contactBanner}
            style={{width:"100%"}}
            // style={{height:"564px",width:"1670px"}}
          ></img>
        </div>
  
        <br />
  
        <ContactInfo></ContactInfo>
  
        <br />
        <ContactUs></ContactUs>
        <br />
        <Footer></Footer>
      </>
    );
  };
  
  export default ContactPage;

import React from "react";
import { Header } from "../../Components/Header/Carousals/Header/Header";
import { Footer } from "../../Components/Footer/Footer";
import { Blogs } from "../../Sections/Blogs";
// import blogCover from "../../img/Blogs3.jpg";
import blogGif from "../../img/blogs.png";
import AOS from "aos";
// import blogDetail1 from "../../img/blogDetail.png"
// import blogDetail1 from "../../img/blogDetail.jpg"
import Grid from "@mui/material/Grid";
import { Paper, Typography } from "@mui/material";
import { Container } from "@mui/material";
import { Box } from "@mui/material";
// import "./NewBlog1.css";
import blogDetail1 from "../../img/blogDetail.jpg";
import blogCover from "../../img/background12.avif";
import blogBanner from "../../img/blogBanner.png";
import { useTranslation } from "react-i18next";
// BlogsPage
import "./Blogs.css";

const BlogsPage = () => {
  const { t } = useTranslation();

  AOS.init({
    duration: 1000
  });
  return (
    <>
      {/* <h1 style={{color:"white"}}>Hello</h1>  */}
      <Header></Header>
      <br />
      <br />
      <div>
        <img style={{width:"100%"}} src={blogBanner}></img>
      </div>
      {/* <Box
        className="blog-banner"
        style={{ backgroundImage: `url(${blogCover})` }}
      > */}
      {/* <img src="{blogCover}"></img> */}
      <Box style={{ backgroundImage: `url(${blogCover})` }}>
        <Container fixed>{/* <img src={blogGif}></img> */}</Container>

        <Grid className="GridBlogContainer" container spacing={0}>
          <Container fixed>
            <Typography
              className="blog-heading"
              variant="h3"
              style={{ color: "black" }}
            >
              {t("Blog-Page.Main-Heading")}
            </Typography>
          </Container>
          {/* <Grid item xs={1}></Grid>  */}
          <Grid item xs={10} className="center-grid">
            <div className="row">
              <div className="col-lg-4 mt-3">
              <img src={blogDetail1}></img>
              </div>
              <div className="col-lg-8 mt-3">
              <Typography variant="h6" style={{marginLeft:"0px"}} className="side-paragraph">
              {t("Blog-Page.Side-Description")}
            </Typography>
              </div>
            </div>
            {/* <Paper> */}

           
            {/* </Paper> */}

           
          </Grid>
          <Grid item xs={10}>
            {/* <Paper className="blogpara"> */}
            <Typography>
              <Typography variant="h6" style={{ color: "black" }}>
                {t("Blog-Page.Below-Description")}
              </Typography>
            </Typography>
            {/* </Paper> */}
          </Grid>
        </Grid>

        {/* <Grid item xs={1}></Grid>   */}
      </Box>

      {/* <Blogs></Blogs> */}

      {/* <h1 style={{color:"white",background:"black"}}> Hello</h1> */}
      <div>
        <Footer></Footer>
      </div>
    </>
  );
};

export default BlogsPage;

import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBCol,
  MDBRow,
  MDBBtn
} from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import "./Footer.css";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <MDBFooter className="text-center">
      {/* color='white' bgColor='dark' */}
      <MDBContainer className="p-4">
        <h5 className="text-uppercase">{t("Footer.Stay-Connected")}</h5>
        <h6>{t("Footer.Join-Media")}</h6>
        <section className="mb-4">
          <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="https://www.facebook.com/profile.php?id=100092807329724"
            target="blank"
            role="button"
          >
            <MDBIcon fab icon="facebook-f" />
          </MDBBtn>

          <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="https://twitter.com/Trackerstrading"
            target="blank"
            role="button"
          >
            <MDBIcon fab icon="twitter" />
          </MDBBtn>
          {/* 
          <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
            <MDBIcon fab icon='google' />
          </MDBBtn> */}

          <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="https://www.instagram.com/trackers_solutions/"
            target="blank"
            role="button"
          >
            <MDBIcon fab icon="instagram" />
          </MDBBtn>

          {/* <MDBBtn
            outline
            color="light"
            floating
            className="m-1"
            href="#!"
            role="button"
          >
            <MDBIcon fab icon="linkedin-in" />
          </MDBBtn> */}

          {/* <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'>
            <MDBIcon fab icon='github' />
          </MDBBtn> */}
        </section>

        {/* <br /> */}

        {/* <section className=''>
          <form action=''>
            <MDBRow className='d-flex justify-content-center'>
              <MDBCol size="auto">
                <p className='pt-2'>
                  <strong>Sign up for our newsletter</strong>
                </p>
              </MDBCol>

              <MDBCol md='5' className="emailInput" start>
                  
                <MDBInput contrast type='email' label=' Email address' className='mb-4' />
              </MDBCol>

              <MDBCol size="auto">
                <MDBBtn outline color='light' type='submit' className='mb-4'>
                  Subscribe
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </form>
        </section> */}

        <section className="mb-4">
          {/* <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt distinctio earum repellat quaerat
            voluptatibus placeat nam, commodi optio pariatur est quia magnam eum harum corrupti dicta, aliquam
            sequi voluptate quas.
          </p> 
              
          */}
        </section>
        <section className="">
          <MDBRow>
            <MDBCol lg="4" md="6" className="mb-4 mb-md-0">
              <h5 className="text-uppercase">Trackers</h5>

              <div className="list-unstyled1 mb-0">
                {/* <li>
                  <a href='#!' className='text-white'>
                    Link 1
                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                    Link 2
                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                    Link 3
                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                    Link 4
                  </a>
                </li> */}
                <p>{t("Heading_data.heading1.headingcontent")}</p>
              </div>
            </MDBCol>

            <MDBCol lg="4" md="6" className="mb-4 mb-md-0">
              <h5 className="text-uppercase mid-text-box">
                {t("Footer.Contact-No")}
              </h5>

              <ul className="list-unstyled mb-0">
                <li>
                  <a href="#!" className="text-white">
                    Mobile: {t("Contact-page.Contact-info.Mobile")}
                  </a>
                </li>
                <li>
                  <a href="#!" className="text-white">
                    Telephone: {t("Contact-page.Contact-info.Telephone")}
                  </a>
                </li>
                <h5 className="text-uppercase email">EMail ID</h5>
                <li>
                  <a href="#!" className="text-white">
                    Email : info@trackers.ae
                  </a>
                </li>
              </ul>
            </MDBCol>

            <MDBCol lg="4" md="6" className="mb-4 mb-md-0">
              <h5 className="text-uppercase">{t("Footer.Office-Address")}</h5>

              <ul className="list-unstyled mb-0">
                {/* <li>
                  <a href='#!' className='text-white'>
                    Link 1
                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                    Link 2
                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                    Link 3
                  </a>
                </li>
                <li>
                  <a href='#!' className='text-white'>
                    Link 4
                  </a>
                </li> */}
                <p>{t("Contact-page.Contact-info.Location")}</p>
              </ul>
            </MDBCol>

            {/* <MDBCol lg='3' md='6' className='mb-4 mb-md-0'>
              <h5 className='text-uppercase'>India Office Address</h5>

              <ul className='list-unstyled mb-0'>
                              <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt distinctio earum repellat quaerat
            voluptatibus placeat nam, commodi optio pariatur est quia magnam eum harum corrupti dicta, aliquam
            sequi voluptate quas.
          </p>

              </ul>
            </MDBCol> */}
          </MDBRow>
        </section>
      </MDBContainer>

      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        © 2023 Copyright
        <a
          className="text-white"
          href="/"
          //  href='https://mdbootstrap.com/'
        >
          : Trackers.com
        </a>
      </div>
    </MDBFooter>
  );
};

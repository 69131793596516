import React from "react";
import "./ContactInfo.css";
import iconCall from "../../img/icons8-phone-30.png";
import iconMsg from "../../img/icons8-letter-30.png";
import iconLoc from "../../img/icons8-worldwide-location-30.png";
// import iconLoc from "../../img/icons8-location-64.png"
// import iconLoc from "../../img/icons8-location-24.png"
import SectionHeader from "../../Components/SectionHeader";
import { useTranslation } from "react-i18next";

const ContactInfo = () => {
  const { t } = useTranslation();

  return (
    // <section id="contact-us">
    <>
      {/* <div className="reg-container z-index"> */}
      {/* <div className="row c"> */}
      {/* <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12"> */}
      <SectionHeader
        title1={t("Heading_data.heading7.title1")}
        title2={t("Heading_data.heading7.title2")}
        headingcontent={t("Heading_data.heading7.headingcontent")}
        data-aos="fade-up"
      />
      <div className="row c">
        <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <h3>{t("Contact-page.Main-title")}</h3>
          <div className="separator left-align "></div>
          <div className="info" id="info">
            <ul>
              <li>
                <div className="infoList">
                  <div className="iconC">
                    <img  src={iconCall}></img>
                  </div>
                  <div className="contentC">
                    <div className="label">
                      {t("Contact-page.sub-heading.M")} :{" "}
                      {t("Contact-page.Contact-info.Mobile")}
                      <br />
                      {t("Contact-page.sub-heading.T")} :{" "}
                      {t("Contact-page.Contact-info.Telephone")}
                    </div>
                    {/* <div className="value">0123-456-789, 0123-456-789</div> */}
                  </div>
                </div>
              </li>

              <li>
                <div className="infoList">
                  <div className="iconC">
                    <img src={iconMsg}></img>
                  </div>
                  <div className="contentC">
                    <div className="label">
                      {" "}
                      {t("Contact-page.sub-heading.E")} : info@trackers.com
                    </div>
                    {/* <div className="value">info@bizler.com</div> */}
                  </div>
                </div>
              </li>

              <li>
                <div className="infoList">
                  <div className="iconC">
                    <img
                      src={iconLoc}
                      // style={{width:"30px",height:"30px"}}
                    ></img>
                  </div>
                  <div className="contentC">
                    <div className="labelC3">
                      {" "}
                      {t("Contact-page.sub-heading.A")} :{" "}
                      {t("Contact-page.Contact-info.Location")}
                    </div>
                    {/* <div className="value">123, Street Name, City Name, State Name, Country Name, Pin Code : 123456 </div> */}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          {/* <div className="form-header text-center">
                        <h4>Let’s Get In Touch!</h4>
                        <div className="separator">
                            <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                            </ul>
                        </div>
                        <p>It would be great to hear from you! If you got any questions</p>
                    </div>
                    <div className="contact-form">
                        <form action="https://wpshopmart.com/index.html">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div className="form-group">
                                <input value="" type="text" placeholder="Full Name" />
                            </div>
                            <div className="form-group">
                                <input value="" type="text" placeholder="Email" />
                            </div>
                            <div className="form-group">
                                <input value="" type="text" placeholder="Phone No." />
                            </div>
                            <div className="form-group">
                                <input value="" type="text" placeholder="Subject" />
                            </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 form-group">
                            <textarea placeholder="Message"></textarea>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group text-center mar-none">
                            <button type="submit">Send Message <i className="flat flaticon-plane"></i></button>
                            </div>
                        </div>
                        </form>
                    </div> */}

          <div className="row c m-2" id="map">
            {" "}
            {/* <div id="map"> */}
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.4719602263367!2d55.27845597557599!3d25.187301632101136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69268414659b%3A0x57b0c10714f75064!2sTamani%20arts%20building!5e0!3m2!1sen!2sin!4v1685304062538!5m2!1sen!2sin"
              width="600"
              height="450"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade'
              style={{ border: "0", width: "100%", }}
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
      </div>
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </section> */}
    </>
  );
};

export default ContactInfo;

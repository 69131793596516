import React from "react";
import "./IndustriesCard.css"
import {Link} from "react-router-dom"
import AOS from "aos"

const IndustriesCard = ({ content ,img_thumbnail, img_large, cls}) => {
  AOS.init({
    duration: 2000,
  })
  return (
    // <div className={`col-lg-4 col-md-6 col-sm-6 col-xs-12 port-item ${cls}`}>
    <div className={`col-lg-6 col-md-6 col-sm-12 col-xs-12 port-item ${cls}`}>
    <div className="caption-style-2 " data-aos="fade-up">
  
        <img src={img_thumbnail} alt="project"
         className="InImage"
          />
          
        <div className="caption">
        <div class="blur"></div>
            <div className="caption-text">
               {/* <Link to={`/${content}`} className="text-title"> */}
              <h2>{content}</h2>
               {/* <p>So you can imagine your fears with less distraction.</p> */}
              {/* </Link>  */}
              </div>
            {/* <div className="Inlabel-bg"> */}
             
              {/* </div> */}
        </div>
        

        
        {/* <div className="zoom"> <a href={img_large} className="popup-box"  data-lightbox="image" data-title="Project">
             <i className="flat flaticon-magnifying-glass"></i> 
             </a> </div> */}
    </div>
    <h3>{content}</h3>
</div>
    
  );
};
export default IndustriesCard;

import React, { useRef } from "react";
import "./ContactUs.css";
import regImg from "../../img/Dubai2.jpeg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import AOS from "aos";
import { useFormik } from "formik";
// import { Field } from "formik";
// import emailjs from "emailjs-com"
import emailjs from "@emailjs/browser";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const validateInfo = (values, e) => {
  const errors = {};
  if (!values.Name) {
    errors.Name = "Please enter your name !";
  } else if (values.Name.length > 30) {
    const errorMsg = "Must be 30 characters or less";
    errors.Name = errorMsg;
  }

  // if (!values.Email) {
  //   errors.Email = "Please enter correct email-id  !";
  // } else if (values.Email.length < 0) {
  //   errors.lastName = errorMsg;
  // }

  if (!values.Email) {
    errors.Email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Email)) {
    errors.Email = "Not a valid Email";
  }
  // else {
  //   errors.Email = errorMsg;
  // }

  if (!values.Message) {
    // errors.Message = "Message is required";
    errors.Message =
      "Please type a message and get response mail from trackers team !";
  } else if (values.Message.length < 0) {
    const errorMsg =
      "Please type a message and get a response mail from trackers team";
    errors.Message = errorMsg;
  }

  return errors;
};

const regSchema = Yup.object().shape({
  Email: Yup.string()

    // Format Validation
    .email("Invalid email address format")

    // Required Field Validation
    .required("Email is required"),
  Name: Yup.string()

    //Minimum Character Validation

    .required("Name is required"),
  Message: Yup.string().required("Message is required")
});

const ContactUs = () => {
  const { t } = useTranslation();
  const form = useRef();

  AOS.init({
    duration: 2000
  });

  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      Message: ""
    },
    validate: validateInfo,

    onSubmit: (values, e) => {
      alert(JSON.stringify(values, null, 3));
      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      // e.preventDefault();
      // emailjs.sendForm("service_m3y8chm","template_hbalezl",e.target,"wB4BLOVVXxiUY1YDa")
      // .then(res=>{console.log(res)})
      // .catch(err=>{console.log(err)})

      // >>>>>

      // e.preventDefault();
      emailjs
        .sendForm(
          "service_m3y8chm",
          "template_hbalezl",
          form.current,
          "wB4BLOVVXxiUY1YDa"
        )
        .then(
          (result) => {
            alert("message sent successfully...");
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  });

  // const onSubmit=(e)=>{
  //     e.preventDefault();
  //     emailjs.sendForm("service_m3y8chm","template_hbalezl",e.target,"wB4BLOVVXxiUY1YDa").then(res=>{console.log(res)})
  //     .catch(err=>{console.log(err)})
  // }

  return (
    <>
      <div
        className="contact-Image"
        style={{ backgroundImage: `url(${regImg})` }}
      >
        <div
          class="reg-container z-index"
          // style={{ backgroundImage: `url(${regImg})` }}
        >

          <div className="row">
          <div className="col-lg-2 "></div>
          <div className="col-lg-8 ">
          <div
            class="p-3"
            data-scrollreveal="enter bottom and move 150px over 1s"
            data-sr-init="true"
            data-sr-complete="true"
          >
            <div class="contact-wrap" data-aos="zoom-out">
              <p>
                <i class="icon-contact1"></i>
                <span>{t("Registration-Form.Call-Us")} : </span>{" "}
                {t("Contact-page.Contact-info.Telephone")}{" "}
                <small>
                  <em>{t("Registration-Form.Days")} | 9am–5pm (GMT +1)</em>
                </small>
              </p>
              <p>
                <i class="icon-contact1"></i>
                <span>{t("Registration-Form.Visit-Us")} : </span>{" "}
                {t("Contact-page.Contact-info.Location")}
              </p>
              <h6>{t("Registration-Form.Contact-Us")} :</h6>
              <form
                ref={form}
                name="ajax-form"
                id="ajax-form"
                action="mail-it.php"
                method="post"
                onSubmit={formik.handleSubmit}
              >
                <label htmlFor="Name">
                  {" "}
                  {t("Registration-Form.Enter-Name")} :{" "}
                </label>
                <input
                  id="Name"
                  name="Name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.Name}
                  validationSchema={regSchema}
                  // onBlur={formik.handleBlur}
                  // className="form-control"
                />
                {/* {formik.errors.Name ? <div>{formik.errors.Name}</div> : null} */}
                <br />
                {formik.touched.Name && formik.errors.Name ? (
                  <span
                    style={{
                      margin: "5px",
                      color: "#FF5733",
                      fontWeight: "bold",
                      fontSize: "large"
                    }}
                  >
                    {formik.errors.Name}
                  </span>
                ) : null}
                <br />

                <label htmlFor="Name">
                  {t("Registration-Form.Enter-Email")} :{" "}
                </label>
                <input
                  id="Email"
                  name="Email"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.Email}
                  validationSchema={regSchema}
                />
                <br />
                {formik.touched.Email && formik.errors.Email ? (
                  <span
                    style={{
                      margin: "5px",
                      color: "#FF5733",
                      fontWeight: "bold",
                      fontSize: "large"
                    }}
                  >
                    {formik.errors.Email}
                  </span>
                ) : null}
                <br />
                <label htmlFor="Name">
                  {t("Registration-Form.Tell-Us")} :{" "}
                </label>
                <br />
               <div className="d-flex justify-content-center">
               <textarea
                  id="Message"
                  name="Message"
                  component="textarea"
                  className=" textareawidth"
                  rows="3"
                  // style={{ width: '50%' }}
                  // cols={35}
                  onChange={formik.handleChange}
                  value={formik.values.Message}
                  validationSchema={regSchema}
                />
               </div>
                <br />
                {formik.touched.Message && formik.errors.Message ? (
                  <span
                    style={{
                      margin: "5px",
                      color: "#FF5733",
                      fontWeight: "bold",
                      fontSize: "large"
                    }}
                  >
                    {formik.errors.Message}
                  </span>
                ) : null}
                <br />
                <br />
                <button type="submit">{t("Registration-Form.Submit")}</button>
              </form>
            </div>
          </div>
          </div>
          <div className="col-lg-2 "></div>
          </div>

        </div>
      </div>
    </>
  );
};

export default ContactUs;

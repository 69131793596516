import * as React from "react";
import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
// import Container from '@mui/material/Container';
import Avatar from "@mui/material/Avatar";
import "./Header.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ServicesPage } from "../../../../Pages/OurServices/ServicesPage";
import { IndustriesPage } from "../../../../Pages/Industries/IndustriesPage";
import { BlogsPage } from "../../../../Pages/Blogs/BlogsPage";
import { AboutUsPage } from "../../../../Pages/AboutUs/AboutUsPage";
import { ContactPage } from "../../../../Pages/ContactUs/ContactPage";
// import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import LanguageIcon from "@mui/icons-material/Language";
import { translation } from "../../../../../src/store/language/";
// import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { routingConstants } from "../../../../utils/constants";
import trackersLogo from "../../../../img/trackerslogo.png";
import DrawerComponent from "../Header/DrawerComponent";
import theme from "../../../../utils/theme";

// const pages = ["Home",'Our Services','Industries',"Blogs",'About Us',"Contact Us"];
// const Languages = ["English","عربي"];

export const Header = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { t } = useTranslation();
  const history = useHistory();

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Language >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const dispatch = useDispatch();
  const { lan } = useSelector((state) => state.languageReducer);
  const [language, setLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    // dispatch(getAllNotifications());
  }, [lan]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    localStorage.getItem("i18nextLng") === "en"
      ? setLanguage("en")
      : setLanguage("ar");
  }, [lan]);

  const changeLanguage = (e, lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    setAnchorEl(null);
    dispatch(translation(lang));
  };

  return (
    <AppBar>
      <Toolbar>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <img src={trackersLogo} href="/" className="Headerlogo"></img>
              <div className="navlinks">
                <Link to="/" className="nav_links">
                  {t("header.headings.1")}
                </Link>
                <Link to={routingConstants.SERVICES} className="nav_links">
                  {t("header.headings.2")}
                </Link>
                <Link to={routingConstants.INDUSTRIES} className="nav_links">
                  {t("header.headings.3")}
                </Link>
                <Link to={routingConstants.BLOGS} className="nav_links">
                  {t("header.headings.4")}
                </Link>
                <Link to={routingConstants.ABOUT} className="nav_links">
                  {t("header.headings.5")}
                </Link>
                <Link to={routingConstants.CONTACT} className="nav_links">
                  {t("header.headings.6")}
                </Link>
              </div>
            </Box>
          </Box>
        )}

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Change Language">
            <IconButton
              // onClick={handleOpenUserMenu}
              aria-controls="fade-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{
                p: 0,
                // marginTop:"5px"
                padding: "5px",
                margin: "2px"
              }}
            >
              {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
              <LanguageIcon />
              <Typography
                style={{ color: "white", marginBottom: "5px", padding: "5px" }}
              >
                {language.startsWith("en") ? "English" : "عربي"}
              </Typography>
              {/* <Typography style={{color:"white",background:"black",marginBottom:"5px"}} >&nbsp;&nbsp;Languages</Typography> */}
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            // anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            // open={Boolean(anchorElUser)}
            // onClose={handleCloseUserMenu}

            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {/* {Languages.map((Languages) => (
                <MenuItem key={Languages} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{Languages}</Typography>
                  
                </MenuItem>
              ))} */}

            <MenuItem onClick={(e) => changeLanguage(e, "en")}>
              English
            </MenuItem>
            <MenuItem onClick={(e) => changeLanguage(e, "ar")}>عربي</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
